import { propEq, find, isEmpty } from 'ramda'
// import store from './state/store'
// import { clamp, distance } from '@popmotion/popcorn'

// export interface Position {
//   top: number;
//   height: number;
// }

// Prevent rapid reverse swapping
const buffer = 5

// export const findIndex = (i, yOffset, positions) => {
//   let target = i
//   const { top, height } = positions[i]
//   const bottom = top + height

//   // If moving down
//   if (yOffset > 0) {
//     const nextItem = positions[i + 1]
//     if (nextItem === undefined) return i

//     const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer
//     if (yOffset > swapOffset) target = i + 1

//     // If moving up
//   } else if (yOffset < 0) {
//     const prevItem = positions[i - 1]
//     if (prevItem === undefined) return i

//     const prevBottom = prevItem.top + prevItem.height
//     const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer
//     if (yOffset < -swapOffset) target = i - 1
//   }

//   return clamp(0, positions.length, target)
// }

export const findId = (id, arr) => find(propEq('id', id), arr)

export const money = amount => {
  // const currency = new Intl.NumberFormat('en-IN', {
  //   style: 'decimal',
  //   maximumFractionDigits: 2
  // }).format(amount / 100)
  const currency = amount / 100
  return currency
}

export const isVenueOpen = venue => {
  let result = false

  if (venue) {
    const daysOfWeek = {
      0: 'sun',
      1: 'mon',
      2: 'tue',
      3: 'wed',
      4: 'thu',
      5: 'fri',
      6: 'sat'
    }

    const workingHours = venue.workingHours
    const now = Date.now()
    // console.log('now: ', now, new Date(now))
    const dayOfWeek = daysOfWeek[new Date(now).getDay()]
    const [openTime, closeTime] = workingHours[dayOfWeek]
    const open = new Date(now).setHours(...openTime.split(':'), 0)
    // console.log('open: ', open, new Date(open))
    const close = new Date(now).setHours(...closeTime.split(':'), 0)
    result = open < now && now < close
  }

  return result
}

export const ellipsizeTextBox = (el, row) => {
  el = el.current
  const styles = window.getComputedStyle(el)
  let wordArray = el.innerHTML.split(' ')
  while (el.scrollHeight > parseFloat(styles.lineHeight) * row + 1) {
    wordArray.pop()
    el.innerHTML = wordArray.join(' ') + ' ...'
  }
}

export const combineSameDishes = (orders, optionsets) => {
  // TODO: теряется порядок в котором пользователь заказывал блюда
  // Нужен глобальный рефакторинг чтобы не считать стоимость опций каждый раз, а получать их уже в объекте order

  const result = orders.reduce((acc, v) => {
    const totalPrice = isEmpty(v.selectedOptions) ? v.price : v.price + calcOptionsCost(v.selectedOptions, optionsets)

    const optionsHash = v.selectedOptions.reduce((acc, v) => {
      acc += v.id.toString() + v.value.toString()
      return acc
    }, '')

    const idHash = `${v.dishId}OPTIONS${optionsHash}`

    acc[idHash] = acc[idHash]
      ? { ...acc[idHash], count: acc[idHash].count + 1 }
      : { dishId: v.dishId, totalPrice: totalPrice, count: 1, selectedOptions: v.selectedOptions }
    return acc
  }, {})

  return Object.values(result)
}

export const optionsToText = (options, optionsets) => {
  const result = options.map(({ id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    if (!optionset) return ''
    const ingredient = optionset.options.find(({ id }) => id === parseInt(value))

    return `${optionset.name.toLowerCase()}: ${ingredient.name.toLowerCase()}`
  })

  return result.join(', ')
}

export const calcOptionsCost = (options, optionsets) => {
  if (!optionsets || optionsets.length === 0) {
    return 0
  }
  const total = options.reduce((acc, { id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    // console.log(optionset, id, value, optionsets)
    const option = optionset === undefined ? { price: 0 } : optionset.options.find(({ id }) => id === parseInt(value))

    acc += parseInt(option.price)
    return acc
  }, 0)

  return total
}

export const comparePosition = (a, b) => {
  return parseInt(a.position) - parseInt(b.position)
}

export const translate = (id, lang, translations) => {
  const fallbacks = languageFallback(lang)

  // проверить существует ли такой объект в переводах
  if (translations.hasOwnProperty(id)) {
    const locales = translations[id]

    // проверить существует ли такой язык в переводах
    // если нет то использовать стратегии замещения языка
    if (locales.hasOwnProperty(lang)) {
      return locales[lang]
    } else {
      for (const fallbackLang of fallbacks) {
        if (locales.hasOwnProperty(fallbackLang)) {
          return locales[fallbackLang]
        } else {
          return 'fallback error'
        }
      }
    }
  } else {
    return 'empty'
  }
}

const languageFallback = lang => {
  const strategies = {
    ru: ['en'],
    ua: ['ru', 'en'],
    de: ['en'],
    default: ['en']
  }

  return strategies.hasOwnProperty(lang) ? strategies[lang] : strategies['default']
}

export const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
      return false // Chrome
    } else {
      return true // Safari
    }
  }
  return false
}

export const isOptionInStoplist = (stoplist, id) => {
  const { options } = stoplist
  return options.indexOf(id) >= 0
}

export const isDishInStoplist = (stoplist, id) => {
  const { dishes } = stoplist
  return dishes.indexOf(id) >= 0
}

/**
 * @todo this is just draft. Needs complete rewrite.
 * @param {string|object} text - data to translate
 * @returns {string} translated text
 * @function
 */
export const t = (text, locale) => {
  if (!text) {
    return ''
  }
  if (typeof text === 'string') {
    return text
  }
  const isLocalized = tPicker(text, locale) // 'ru' | 'en' | 'uk' | false

  return isLocalized ? text[isLocalized] : ''
}

export const tPicker = (text, locale) => {
  //
  if (locale && locale in text) return locale
  if ('ru' in text) return 'ru'
  if ('en' in text) return 'en'
  if ('uk' in text) return 'uk'
  return false
}

export const timeRange = (t = 0) => {
  if (t <= 5) return [3, 5]
  if (t > 15) return [20, 25]

  const ceil5 = x => Math.ceil(x / 5) * 5
  const min = ceil5(t)
  const max = ceil5(min * 1.3)
  return [min, max]
}

export const uriTransformer = uri => {
  const protocols = ['http', 'https', 'mailto', 'tel', 'tg']
  const url = (uri || '').trim()
  const first = url.charAt(0)

  if (first === '#' || first === '/') {
    return url
  }

  const colon = url.indexOf(':')
  if (colon === -1) {
    return url
  }

  const length = protocols.length
  let index = -1

  while (++index < length) {
    const protocol = protocols[index]

    if (colon === protocol.length && url.slice(0, protocol.length).toLowerCase() === protocol) {
      return url
    }
  }

  index = url.indexOf('?')
  if (index !== -1 && colon > index) {
    return url
  }

  index = url.indexOf('#')
  if (index !== -1 && colon > index) {
    return url
  }

  // eslint-disable-next-line no-script-url
  return 'javascript:void(0)'
}

export const getCookingTime = (dishes, oldOrders) => {
  let time = 0
  let max = 0
  // console.log(dishes, oldOrders)
  if (dishes.length === 0) {
    return { max: 0, time: '' }
  }
  const findMaxCategory = orders => {
    let cats = []
    orders.forEach(d => {
      const e = dishes.filter(dish => dish.id === d.dishId)[0]
      if (!e) {
        return []
      }
      const ww = cats.filter(c => c.id === e.catId)
      if (!e) {
        return []
      }
      if (ww.length === 0) {
        cats = [
          ...cats,
          {
            id: e.catId,
            time: e.waitingTime
          }
        ]
      } else {
        cats = cats.map(c => {
          if (c.id === e.catId) {
            return {
              ...c,
              time: c.time + e.waitingTime
            }
          } else {
            return c
          }
        })
      }
    })
    if (cats.length > 0) {
      return cats.sort((b, a) => a.time - b.time)[0].id
    } else {
      return []
    }
  }
  const findMaxTotalTime = (catId, orders) => {
    let dishArr = orders.map(o => dishes.filter(d => d.id === o.dishId)[0])
    if (dishArr) {
      dishArr = dishArr.filter(di => di && di.catId === catId)
    } else {
      return 0
    }
    const res = dishArr
      .sort((b, a) => a.waitingTime - b.waitingTime)
      .map((da, ind) => {
        if (ind === 0) {
          return da.waitingTime
        } else {
          return da.waitingTime ? da.waitingTime / 3 : 0
        }
      })
    return res.reduce((total, curr) => total + curr, 0)
  }
  // let min = findMinDish(oldOrders)
  // console.log('[findMaxCategory(oldOrders)]', findMaxCategory(oldOrders))
  max = findMaxTotalTime(findMaxCategory(oldOrders), oldOrders)
  // todo fixme временно
  // max = max * 1.5

  /**
   * @todo получать эти данные из настроек
   * конвертация времени в минуты
   * ограничение максимального времени
   */
  max /= 60
  max = max > 25 ? 25 : max

  if (oldOrders.filter(x => x.takeout === false).length === 0) {
    time = `${Math.round(max)} мин.`
  } else {
    time = `${Math.round(max)} мин.`
  }

  return { max, time }
}

export const copyToClipboard = (string, elemId) => {
  const el = document.createElement('textarea')
  el.value = string
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  if (elemId) {
    const elemToAppend = document.getElementById(elemId)
    elemToAppend.appendChild(el)
    el.select()
    document.execCommand('copy')
    elemToAppend.removeChild(el)
  } else {
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }
}

/**
 *
 * @param {string} url
 * @returns {string}
 * temp solution
 */
export const getMediaUrl = url => {
  if (url) {
    return `https://${window.mode !== 'production' ? 'dev.' : ''}${window.busytable.domain}${
      url.startsWith('/') ? url : `/${url}`
    }`
  }
  return ''
}

export const reorderListByIndex = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
